<template>
	<layout
			:activeIndex="activeIndex"
			:activeModel.sync="activeModel"
			:topBarItems="topBarItems"
			@setIndex="setIndex"
	>
		<template #background>
			<div
					:style="'background: url(' + imgUrl + ') center center / cover;'"
					class="position-absolute"
					style="width: 100vw;height: calc(100vh - 4.45rem);top: -1.5rem;left: -2rem;background: black;"
			></div>
		</template>
		<template #left-0>
			<b-card>
				<b-card-title>模式选择</b-card-title>
				<b-button-group class="w-100">
					<b-button
							@click="selected = 1"
							:variant="(selected === 1 ? '' : 'outline-') + 'success'"
					>
						<feather-icon class="mr-50" icon="SunIcon" size="18"/>
						<span style="line-height: 18px">白天</span>
					</b-button>
					<b-button
							@click="selected = 2"
							:variant="(selected === 2 ? '' : 'outline-') + 'success'"
					>
						<feather-icon class="mr-50" icon="MoonIcon" size="18"/>
						<span style="line-height: 18px">夜晚</span>
					</b-button>
				</b-button-group>
			</b-card>
		</template>
		<!--		<template #left-1></template>-->
		<template #right-0>
			<div class="position-absolute" style="right: 1rem; width: 30rem;z-index: 2">
				<b-card>
					<b-row no-gutters>
						<b-col v-for="(item, key,index) in equipmentList" :key="'lvl_1+' + key" cols="6">
							<b-form-checkbox
									:checked="item.light_status"
									:disabled="!item.equip_key"
									style="z-index: 2 !important"
							    switch
									@change="lightSwitch(key, item)"
							>
								{{ item.equip_name }}
							</b-form-checkbox>
						</b-col>
					</b-row>
				</b-card>
			</div>

		</template>
		<!--		<template #right-1></template>-->
		<!--		<template #right-3>-->
		<!--			<panel-group :show-title="false" group-uid="8c24cf19"></panel-group>-->
		<!--		</template>-->
	</layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import {onMounted, reactive, ref, toRefs, watch} from "@vue/composition-api";
import {showToast} from "@/libs/utils/showToast";
import store from "@/store";
import {routerParams} from "@/libs/utils/routerParams";
// import LeftMenu from "@/views/front/project-jsf/subsystems/layout/components/LeftMenu";

export default {
	name: "floodlighting",
	components: {
		Layout,
		// panelGroup: () => import("@/views/panel-group"),
		// LeftMenu,
	},
	setup() {
		const project_id = routerParams("project_id");
		const system_key = routerParams("system_key");
		// 框架相关参数
		const layoutParams = reactive({
			activeIndex: 0,
			activeModel: "",
			topBarItems: [
				{
					title: "主页",
					// model: {path: 'JSF-Building/jsf', dbIds: [], rotate: '2687,-6337,610'}
				},
				/* { title: "模式" }, */
				/* { title: "数据分析" }, */
			],
		});
		//左侧菜单相关参数
		const leftMenuParams = reactive({
			floors: [],
			system: true,
		});

		const equipmentList = ref([])
		// 左侧菜单选中事件
		const leftMenuSelect = (event) => {
			console.log("leftMenuSelectEvent", event);
			// activeModel.value = event.model
		};

		const setIndex = (e) => {
			layoutParams.activeIndex = e;
		};

		const data = reactive({
			selected: 1,
			imgUrl: "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/projects/72_bg_1.png",
		});

		watch(
				() => data.selected,
				(newVal) => {
					// layoutParams.topBarItems[0].model.mode = newVal
					if (newVal === 1) {
						data.imgUrl =
								"https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/projects/72_bg_1.png";
					} else {
						data.imgUrl =
								"https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/projects/72_bg_2.png";
					}
				}
		);

		//灯光开关
		const lightSwitch = (key, item) => {
			let status = item.light_status
			//若存在灯光控制参数，则实现灯光控制。-1开启，0关闭
			if (item.params && item.params[0]) {
				let command_list = [{
					tag_name: item.params[0].tag_name,
					cur_value: status ? 0 : -1
				}]
				submit_command(command_list)
			} else {
				showToast(null, '提示', '此点位未配置控制信号')
			}
		};

		//发送请求修改控制参数
		const submit_command = (list) => {
			console.log('command_list', list)
			// store
			// 		.dispatch("jsf35/submitCommand", {project_id: 72, list: list})
			// 		.then((response) => {
			// 			showToast(null, "成功", "指令已下发成功！");
			// 		})
			// 		.catch((error) => {
			// 			showToast(error);
			// 		});
		};

		//获取树形菜单
		const fetchEquipmentTree = async (system_type = system_key) => {
			showToast(null, "提示", "正在获取设备列表");
			store
					.dispatch("jsf35/fetchEquipmentTree", {project_id: project_id, system_type})
					.then((res) => {
						showToast(null, "成功", "设备列表获取成功");
						let list = {};
						res.data.list.map((equip_item) => {
							let param = equip_item.params[0]
							// 将灯光设备的参数转换为switch开关
							let light_status = !!param.cur_value;
							if (param.meter_code.endsWith('_C')) {
								list[equip_item["equip_key"]] = equip_item;
								list[equip_item["equip_key"]]['equip_name'] = equip_item["equip_name"].replace('-控制', '');
								list[equip_item["equip_key"]]['light_status'] = light_status;
							}
						});
						equipmentList.value = list
						console.log("equipmentList", equipmentList.value);
					})
					.catch((error) => {
						showToast(error);
					});
		};
		onMounted(() => {
			fetchEquipmentTree();
		});

		return {
			...toRefs(layoutParams),
			...toRefs(leftMenuParams),
			leftMenuSelect,
			setIndex,
			equipmentList,
			lightSwitch,
			...toRefs(data),
		};
	},
};
</script>

<style lang="scss" scoped></style>
